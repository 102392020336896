@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'halyard-display', sans-serif;
    font-weight: 800;
}

#signature img {
    display: inline;
}